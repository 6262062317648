import "core-js/modules/es.array.reduce.js";
import VueApexCharts from "vue-apexcharts";
// const sampData = {
//   quick_sms: 170,
//   group_sms: 36,
//   excel_sms: 6,
//   quick_voice: 9,
//   group_voice: 0,
//   excel_voice: 1,
//   wallet: 2,
//   bundle_history_sms: 1,
//   bundle_history_voice: 1,
//   sender_ids: 26,
// };
export default {
  name: "reportChart",
  components: {
    VueApexCharts
  },
  data() {
    return {
      chartData: {},
      series: [],
      loading: false,
      date: new Date(),
      chartOptions: {
        colors: ["#008ffb", "#FFC107", "#00e396", "#008ffb", "#FFC107", "#00e396", "#ff4560", "#a58cff", "#F7921C"],
        chart: {
          type: "bar",
          stacked: true
        },
        xaxis: {
          categories: ["SMS", "Voice", "Bundle History", "Wallet", "Sender IDs"]
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900
                }
              }
            }
          }
        },
        legend: {
          formatter: function (seriesName, opts) {
            return `<div style="border-right:1px solid #000; padding-right:10px">
                ${seriesName} : ${opts.w.globals.series[opts.seriesIndex].reduce((a, b) => a + b, 0)}
                </div>`;
          }
        }
      }
    };
  },
  methods: {
    async fetchReportData(date) {
      try {
        this.loading = true;
        this.chartData = await this.$store.dispatch("admin/reporting/fetchTheoBotReportChart", date);
        // this.chartData = sampData;
        this.series = this.prepareSeries(this.chartData);
      } catch (error) {
        this.$message.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    prepareSeries(data) {
      const series = [{
        name: "Quick SMS",
        data: [data.quick_sms, 0, 0, 0, 0]
      }, {
        name: "Group SMS",
        data: [data.group_sms, 0, 0, 0, 0]
      }, {
        name: "Excel SMS",
        data: [data.excel_sms, 0, 0, 0, 0]
      }, {
        name: "Quick Voice",
        data: [0, data.quick_voice, 0, 0, 0]
      }, {
        name: "Group Voice",
        data: [0, data.group_voice, 0, 0, 0]
      }, {
        name: "Excel Voice",
        data: [0, data.excel_voice, 0, 0, 0]
      }, {
        name: "Bundle History SMS",
        data: [0, 0, data.bundle_history_sms, 0, 0]
      }, {
        name: "Bundle History Voice",
        data: [0, 0, data.bundle_history_voice, 0, 0]
      }, {
        name: "Wallet",
        data: [0, 0, 0, data.wallet, 0]
      }, {
        name: "Sender IDs",
        data: [0, 0, 0, 0, data.sender_ids]
      }];
      return series;
    }
  },
  props: {
    dateTime: {
      type: Array,
      required: true
    }
  },
  watch: {
    dateTime: {
      handler: function (newVal, oldVal) {
        this.fetchReportData({
          dateFrom: newVal[0],
          dateTo: newVal[1]
        });
      },
      deep: true
    }
  }
};