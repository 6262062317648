import "core-js/modules/es.string.replace-all.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "width": "100%",
      "margin-block": "1rem"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "Filter By Type",
      "size": "small"
    },
    on: {
      "change": function ($event) {
        return _vm.fetchReports(1);
      }
    },
    model: {
      value: _vm.selectedType,
      callback: function ($$v) {
        _vm.selectedType = $$v;
      },
      expression: "selectedType"
    }
  }, _vm._l(_vm.availableTypesComp, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      "height": "43vh",
      "data": _vm.compTableData,
      "size": "small"
    }
  }, _vm._l(_vm.availableColumnsComp, function (column, index) {
    return _c('div', [column == 'message' && _vm.reportType == 'sms' ? _c('el-table-column', {
      key: index,
      attrs: {
        "label": column.replaceAll('_', ' '),
        "prop": column
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c('div', [scope.row.message.length > 15 ? _c('el-popover', {
            attrs: {
              "trigger": "hover",
              "content": scope.row.message
            }
          }, [_c('span', {
            attrs: {
              "slot": "reference"
            },
            slot: "reference"
          }, [_vm._v(_vm._s(scope.row.message.slice(0, 15)) + "...")])]) : _c('span', [_vm._v(_vm._s(scope.row.message))])], 1)];
        }
      }], null, true)
    }) : column == 'campaign_name' ? _c('el-table-column', {
      key: index,
      attrs: {
        "label": column.replaceAll('_', ' '),
        "prop": column
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c('div', [scope.row.campaign_name.length > 15 ? _c('el-popover', {
            attrs: {
              "trigger": "hover",
              "content": scope.row.campaign_name
            }
          }, [_c('span', {
            attrs: {
              "slot": "reference"
            },
            slot: "reference"
          }, [_vm._v(" " + _vm._s(scope.row.campaign_name.slice(0, 15)) + "... ")])]) : _c('span', [_vm._v(_vm._s(scope.row.campaign_name))])], 1)];
        }
      }], null, true)
    }) : column == 'message_type' ? _c('el-table-column', {
      key: index,
      attrs: {
        "label": column.replaceAll('_', ' '),
        "prop": column
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c('div', [_c('el-tag', {
            attrs: {
              "size": "small",
              "effect": "dark",
              "type": _vm.manageTagColors
            }
          }, [_vm._v(_vm._s(scope.row.message_type))])], 1)];
        }
      }], null, true)
    }) : column == 'created_at' ? _c('el-table-column', {
      key: index,
      attrs: {
        "label": "Date & Time",
        "prop": column
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c('div', [_vm._v(_vm._s(_vm.moment(scope.row.created_at).format("ll hh:mm A")))])];
        }
      }], null, true)
    }) : _c('el-table-column', {
      key: index,
      attrs: {
        "label": column.replaceAll('_', ' '),
        "prop": column
      }
    })], 1);
  }), 0), _c('div', {
    staticClass: "col-md-12",
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.pageSize,
      "total": _vm.total
    },
    on: {
      "current-change": _vm.handlePageChange
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };