import CampaignReportTable from "./CampaignReportTable.vue";
import senderidTable from "../../senderIDs/senderidTable.vue";
import WalletHistory from "./WalletHistory.vue";
import BundleHistory from "./BundleHistory.vue";
import reportChart from "./reportChart.vue";
export default {
  name: "theobot",
  components: {
    CampaignReportTable,
    senderidTable,
    WalletHistory,
    BundleHistory,
    reportChart
  },
  data() {
    return {
      tabsVModel: "overview",
      properties: {
        name: "",
        storeDataName: "fetchTheoBotSenderIDs",
        from: "",
        status: ""
      },
      selectDate: []
    };
  },
  computed: {
    formattedDate() {
      return this.selectDate.map(date => {
        return new Date(date).toISOString().split("T")[0];
      });
    }
  },
  mounted() {
    this.selectDate = [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()];
  }
};