import store from "@/state/store.js";
import CompletePayment from "./CompletePayment.vue";
import downloadcsv from "@/helpers/downloadcsv";
export default {
  data() {
    return {
      depositHistory: [],
      page: 1,
      pageSize: 10,
      total: 0,
      search: "",
      loading: false,
      button_loading: false,
      tableLoadingData: false
    };
  },
  components: {
    CompletePayment
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.refetchHistory();
    },
    exportWalletHistory() {
      // this.exportLoading = true
      this.button_loading = true;
      store.dispatch("wallet/exportWalletHistory", this.$route.params.id).then(data => {
        downloadcsv(data, `${localStorage.getItem("userName").replace(/ /g, "_")}_Wallet_History_.csv`);
        this.button_loading = false;
      }).finally(() => this.button_loading = false);
    },
    checkPayment({
      transaction_id
    }) {
      this.tableLoadingData = true;
      let payload = {
        transaction_id: transaction_id.ref_id
      };
      store.dispatch("wallet/checkPaymentStatus", payload).then(data => {
        this.$notify({
          message: this.$t('payment-completed'),
          type: "success"
        });
      }).catch(err => {
        this.$notify({
          message: this.$t('payment-failed'),
          type: "error"
        });
      }).finally(() => this.refetchHistory());
    },
    refetchHistory() {
      this.tableLoadingData = true;
      store.dispatch("admin/reporting/fetchTheoBotWalletHistory", {
        page: this.page,
        date: this.$props.dateTime
      }).then(response => {
        this.depositHistory = response.data;
        this.tableLoadingData = false;
      });
    },
    processAmount(amount) {
      const parsedAmount = Number(amount);
      const language = store.getters["language/currentLanguage"];
      return parsedAmount.toLocaleString(language == "fr" ? "fr-CI" : "en-US", {
        style: "currency",
        currency: language == "fr" ? "CFA" : "GHS"
      });
    }
  },
  computed: {
    filteredTable() {
      return this.depositHistory.filter(item => {
        return item.ref_id.toLowerCase().includes(this.search.toLowerCase()) || item.payment_date.toLowerCase().includes(this.search.toLowerCase()) || item.amount.toLocaleString("en-US", {
          style: "currency",
          currency: "GHC"
        }).toLowerCase().includes(this.search.toLowerCase()) || item.momo_num.toLowerCase().includes(this.search.toLowerCase()) || item.payment_method.toLowerCase().includes(this.search.toLowerCase()) || item.balance_before_deposit.toLocaleString("en-US", {
          style: "currency",
          currency: "GHC"
        }).toLowerCase().includes(this.search.toLowerCase()) || item.top_up_by.toString().toLowerCase().includes(this.search.toLowerCase()) || item.status_message.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    dynamicHeight() {
      if (screen.height < 749) {
        return "50vh";
      } else {
        return "55vh";
      }
    }
  },
  props: {
    dateTime: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    dateTime: {
      handler() {
        this.refetchHistory();
      },
      deep: true
    }
  }
};