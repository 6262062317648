import store from "@/state/store.js";
import moment from "moment";
import downloadcsv from "@/helpers/downloadcsv";
export default {
  data() {
    return {
      bundleHistory: [],
      page: 1,
      pageSize: 10,
      total: 0,
      search: "",
      loading: false,
      button_loading: false
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.fetchData();
    },
    exportBundleHistory() {
      // this.exportLoading = true
      this.button_loading = true;
      store.dispatch("wallet/exportBundleHistory", this.$route.params.id).then(data => {
        downloadcsv(data, `${localStorage.getItem("userName").replace(/ /g, "_")}_Bundle_History_.csv`);
        this.button_loading = false;
      }).finally(() => this.button_loading = false);
    },
    fetchData() {
      this.loading = true;
      store.dispatch("admin/reporting/fetchTheoBotBundleHistory", {
        date: this.$props.dateTime,
        page: this.page
      }).then(response => {
        this.bundleHistory = response.data;
        // if bundle tracker in bundle history is null, replace with empty object
        this.bundleHistory.forEach(item => {
          if (item.bundle_tracker == null) {
            item.bundle_tracker = {
              sms_balance: "",
              voice_balance: "",
              wallet: ""
            };
          }
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  computed: {
    getBundleHistory() {
      return this.bundleHistory;
    },
    filteredTable() {
      return this.bundleHistory.filter(item => {
        var _item$bundle_tracker;
        return (
          // item.ref_id.toLowerCase().includes(this.search.toLowerCase()) ||
          // item.payment_date.toLowerCase().includes(this.search.toLowerCase()) ||
          item.amount.toLocaleString("en-US", {
            style: "currency",
            currency: "GHC"
          }).toLowerCase().includes(this.search.toLowerCase()) || item.message_type.toLowerCase().includes(this.search.toLowerCase()) || moment(item.created_at).format("MM-DD-YYYY").toString().toLowerCase().includes(this.search.toLowerCase()) || item.message_type.toLowerCase().includes(this.search.toLowerCase()) || item.bundle_tracker.wallet.toString().toLowerCase().includes(this.search.toLowerCase()) || item.bundle_tracker.sms_balance.toString().toLowerCase().includes(this.search.toLowerCase()) || ((_item$bundle_tracker = item.bundle_tracker) === null || _item$bundle_tracker === void 0 ? void 0 : _item$bundle_tracker.voice_balance.toString().toLowerCase().includes(this.search.toLowerCase()))
        );
      });
    }
  },
  props: {
    dateTime: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    dateTime: {
      handler(oldVal, newVal) {
        this.fetchData();
      },
      deep: true
    }
  }
};