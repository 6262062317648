var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main-page"
  }, [_vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_c('el-spinner', {
    attrs: {
      "fullscreen": false
    }
  })], 1) : _c('div', {
    staticClass: "chart-section"
  }, [_vm.series.length > 0 ? _c('VueApexCharts', {
    attrs: {
      "type": "bar",
      "height": "400",
      "width": "100%",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  }) : _c('div', {
    staticClass: "no-data"
  }, [_c('h3', [_vm._v("No data available")])])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };