var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "margin-block": "1rem"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "size": "mini",
      "type": "daterange",
      "range-separator": "To",
      "start-placeholder": "Start date",
      "end-placeholder": "End date"
    },
    model: {
      value: _vm.selectDate,
      callback: function ($$v) {
        _vm.selectDate = $$v;
      },
      expression: "selectDate"
    }
  })], 1), _c('el-tabs', {
    model: {
      value: _vm.tabsVModel,
      callback: function ($$v) {
        _vm.tabsVModel = $$v;
      },
      expression: "tabsVModel"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": "Overview",
      "name": "overview"
    }
  }, [_c('reportChart', {
    attrs: {
      "dateTime": _vm.formattedDate
    }
  })], 1), _c('el-tab-pane', {
    attrs: {
      "label": "SMS Reports",
      "name": "sms"
    }
  }, [_c('CampaignReportTable', {
    attrs: {
      "reportType": "sms",
      "dateTime": _vm.formattedDate
    }
  })], 1), _c('el-tab-pane', {
    attrs: {
      "label": "Voice Reports",
      "name": "voice"
    }
  }, [_c('CampaignReportTable', {
    attrs: {
      "reportType": "voice",
      "dateTime": _vm.formattedDate
    }
  })], 1), _c('el-tab-pane', {
    attrs: {
      "label": "Sender IDs",
      "name": "sender_ids"
    }
  }, [_c('senderidTable', {
    staticClass: "sender-id-table",
    attrs: {
      "parent": _vm.properties,
      "theoBotMode": true,
      "dateTimeTheoBot": _vm.formattedDate
    }
  })], 1), _c('el-tab-pane', {
    attrs: {
      "label": "Wallet History",
      "name": "wallet_history"
    }
  }, [_c('WalletHistory', {
    attrs: {
      "dateTime": _vm.formattedDate
    }
  })], 1), _c('el-tab-pane', {
    attrs: {
      "label": "Bundle History",
      "name": "bundle_history"
    }
  }, [_c('BundleHistory', {
    attrs: {
      "dateTime": _vm.formattedDate
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };