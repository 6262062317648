var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 512 512"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "xmlns:svgjs": "http://svgjs.com/svgjs",
      "version": "1.1",
      "width": "25",
      "height": "25",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  }, [_c('g', {
    attrs: {
      "transform": "matrix(0.4,0,0,0.4,153.59988384246827,153.5995880126953)"
    }
  }, [_c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0    c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7    C514.5,101.703,514.499,85.494,504.502,75.496z",
      "fill": "#f9aa4d",
      "data-original": "#000000"
    }
  })])]), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }), _c('g', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg"
    }
  })])]), _vm._v(" Complete Payment ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };