import "core-js/modules/es.array.push.js";
/**
 * @typedef {{current_page: number, data: Array, first_page_url: string, from: number, last_page: number, last_page_url: string, next_page_url: string, path: string, per_page: number, prev_page_url: string, to: number, total: number}}  dataWithPagination
 */

import moment from "moment";
export default {
  name: "sms_reports_theobot",
  props: {
    reportType: {
      type: String,
      required: true,
      validator(value) {
        return ["sms", "voice"].includes(value.toLowerCase());
      }
    },
    dateTime: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      /** @type {dataWithPagination} */
      tableData: {},
      pageSize: 50,
      page: 1,
      tableLoading: false,
      availableTypes: [{
        label: "Quick SMS",
        value: "quicksms"
      }, {
        label: "Group SMS",
        value: "groupsms"
      }, {
        label: "Excel SMS",
        value: "excelsms"
      }, {
        label: "Quick Voice",
        value: "quickvoice"
      }, {
        label: "Group Voice",
        value: "excelvoice"
      }, {
        label: "Excel Voice",
        value: "groupvoice"
      }],
      availableColumns: ["id", "user_id", "campaign_id", "route_name", "message", "campaign_name", "sender_id", "total_recipients", "total_sms_used", "total_voice_used", "wallet_charge", "created_at", "message_type"],
      selectedType: this.$props.reportType === "sms" ? "quicksms" : "quickvoice"
    };
  },
  computed: {
    availableTypesComp() {
      return this.availableTypes.filter(item => {
        return item.label.split(" ")[1].toLowerCase().includes(this.$props.reportType.toLowerCase());
      });
    },
    /** @returns {dataWithPagination['data']} */
    compTableData() {
      return this.tableData.data;
    },
    /** @returns {dataWithPagination['total']} */
    total() {
      return this.tableData.total;
    },
    availableColumnsComp() {
      return this.availableColumns.filter(item => {
        if ((item == "total_sms_used" || item == "message" || item == "sender_id") && this.$props.reportType === "voice") {
          return false;
        }
        if (item == "total_voice_used" && this.$props.reportType === "sms") {
          return false;
        }
        if (item == "id" || item == "user_id" || item == "campaign_id") {
          return false;
        }
        return true;
      });
    },
    manageTagColors() {
      if (this.selectedType.includes("quick")) {
        return "warning";
      }
      if (this.selectedType.includes("group")) {
        return "danger";
      }
      if (this.selectedType.includes("excel")) {
        return "success";
      }
    }
  },
  methods: {
    onCellClick(row) {
      this.$router.push({
        name: "Theo Bot Details",
        params: {
          id: row.campaign_id
        }
      }).catch(() => {});
    },
    async fetchReports(page = 1) {
      try {
        this.tableLoading = true;
        this.tableData = await this.$store.dispatch("admin/reporting/fetchTheoBotReports", {
          report: this.selectedType,
          page: page,
          date: this.$props.dateTime
        });
      } catch (error) {
        this.$message.error(error.message);
      } finally {
        this.tableLoading = false;
      }
    },
    handlePageChange(val) {
      this.page = val;
      this.fetchReports(val);
    }
  },
  watch: {
    dateTime: {
      handler() {
        this.fetchReports(this.page);
      }
    }
  }
};