var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "search-container"
  }, [_c('span', [_c('el-input', {
    attrs: {
      "size": "large",
      "placeholder": _vm.$t('type-to-search'),
      "clearable": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)]), _c('div', {
    staticClass: "table-container"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoadingData,
      expression: "tableLoadingData"
    }],
    attrs: {
      "height": "38vh",
      "data": _vm.filteredTable,
      "size": "small"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": _vm.$t('transaction-id'),
      "width": "200px",
      "prop": "ref_id"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('payment-date'),
      "width": "200px",
      "prop": "payment_date"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('dashboard.wallet.amount'),
      "width": "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.amount.toLocaleString("en-US", {
          style: "currency",
          currency: "GHC"
        }) || 0) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('balance-before-deposit'),
      "width": "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(_vm.processAmount(scope.row.balance_before_deposit)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('account-number'),
      "width": "200px",
      "prop": "momo_num"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.momo_num) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('dashboard.send_message.international_messages.status'),
      "width": "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status == 1 ? _c('p', {
          staticStyle: {
            "margin": "0 auto",
            "color": "#31cd54"
          }
        }, [_vm._v(" SUCCESS ")]) : scope.row.status == 2 ? _c('p', {
          staticStyle: {
            "margin": "0 auto",
            "color": "#ff0066"
          }
        }, [_vm._v(" FAILED ")]) : _c('p', {
          staticStyle: {
            "margin": "0 auto",
            "color": "#ef9e14"
          }
        }, [_vm._v(" PENDING ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('payment-method'),
      "width": "200px",
      "prop": "payment_method"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('top-up-by'),
      "width": "200px",
      "prop": "top_up_by"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.top_up_by.toString()) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": _vm.$t('misc.table.actions'),
      "width": "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status === 0 ? _c('button', {
          staticClass: "complete-payment-icon",
          staticStyle: {
            "margin": "0 auto",
            "color": "#31cd54"
          },
          attrs: {
            "type": "button"
          }
        }, [_c('CompletePayment', {
          nativeOn: {
            "click": function ($event) {
              return _vm.checkPayment({
                transaction_id: scope.row
              });
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.pageSize,
      "total": _vm.total
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };